import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';

import { Helmet } from 'react-helmet';

import CardGrid from '../components/CardGrid';
import Card from '../components/Card';

import '../styles/explore.scss';

const ExplorePage = ({ data }) => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [searchParams, setSearchParams] = useState(null);

  const articles = data.articles.nodes.filter((article) => {
    const articleTags =
      article.pageContext &&
      article.pageContext.metadata &&
      article.pageContext.metadata.tags;

    if (selectedFilters.length > 0 && articleTags && articleTags.length > 0) {
      let matchedTagCount = 0;

      selectedFilters.forEach((filter) => {
        const filterName = filter.getAttribute('name');

        const tags = article.pageContext.metadata.tags;
        if (
          tags.find((tag) => {
            return tag.contentful_id === filterName;
          }) !== undefined
        ) {
          matchedTagCount += 1;
        }
      });

      if (matchedTagCount === selectedFilters.length) {
        return article;
      }
    } else if (selectedFilters.length === 0) {
      return article;
    }
  });

  const tags = data.tags.nodes;

  const querySelectedFilters = () => {
    const selected = document.querySelectorAll(
      '.explore__filters .explore__filter:checked'
    );

    const filterString = [...selected]
      .map((filter) => {
        return filter.id;
      })
      .join(',');

    setSelectedFilters(selected);
    typeof window !== `undefined`
      ? window.history.replaceState(
          { additionalInformation: 'Updated the URL with JS' },
          'Filtered',
          window.location.origin +
            window.location.pathname +
            (filterString ? `?filters=${filterString}` : ``)
        )
      : null;
  };

  useEffect(() => {
    setSearchParams(
      typeof window !== `undefined`
        ? new URLSearchParams(window.location.search)
        : null
    );

    window.location.search
      .replace(/^\?filters=/, '')
      .split(',')
      .forEach((filter) => {
        const el = document.getElementById(filter);
        el && el.click(); // This is a li'l bit hacky…
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Explore{' '}
          {selectedFilters.length > 0
            ? `(${articles.length} item${articles.length !== 1 ? 's' : ''}) `
            : ``}
          • 🔭 Neighbour Institute
        </title>
      </Helmet>
      <div className="explore__wrap flex mx-auto w-full max-w-7xl">
        <div className="hidden md:flex md:flex-col sticky top-0 h-full overflow-auto">
          <div className="flex flex-col flex-grow border-r border-gray-200 bg-white">
            <div className="explore__filters max-h-screen overflow-auto flex-grow flex flex-col px-6 ">
              <fieldset>
                <legend className="text-md py-4 font-bold text-gray-900 sticky top-0 z-10 w-full bg-white">
                  Filter By
                </legend>
                {tags.map((tag) => {
                  return (
                    <div
                      key={tag.contentful_id}
                      className="border-t border-gray-200 divide-y divide-gray-200"
                    >
                      <div className="relative flex items-start explore__filter__wrap">
                        <div className="min-w-0 flex-1 text-sm">
                          <label
                            htmlFor={tag.contentful_id}
                            className="font-medium text-gray-700 select-none flex justify-between py-4"
                          >
                            {tag.name}

                            <input
                              name={tag.contentful_id}
                              id={tag.contentful_id}
                              data-tag-id={tag.contenful_id}
                              onChange={querySelectedFilters}
                              type="checkbox"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded explore__filter"
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </fieldset>
            </div>
          </div>
        </div>
        {/* <button
          type="button"
          className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
        >
          <span className="sr-only">Open sidebar</span>
          <svg
            className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4 6h16M4 12h16M4 18h7"
            />
          </svg>
        </button> */}
        <div className="explore__main h-full overflow-auto flex flex-col flex-1">
          <main className="flex-1 mb-20">
            {selectedFilters.length > 0 && (
              <div className="bg-white border-gray-200 border-bottom-300">
                <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                  <div className="flex items-center justify-between flex-wrap">
                    <div className="w-0 flex-1 flex items-center">
                      <p className="ml-0 font-medium text-white truncate">
                        <span className="md:hidden">
                          {' '}
                          Filters You Have Selected{' '}
                        </span>
                        <span className="md:inline">
                          {[...selectedFilters].map((filter) => {
                            const text = filter.labels[0].innerText;

                            return (
                              <span className="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 mx-2 text-sm font-medium bg-green-100 text-green-800">
                                {text}
                                <label
                                  htmlFor={filter.id}
                                  className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-green-200 hover:text-green-500 focus:outline-none focus:bg-green-500 focus:text-white"
                                >
                                  <span className="sr-only">
                                    Remove large option
                                  </span>
                                  <span className="text-green-800 text-lg">
                                    &#215;
                                  </span>
                                </label>
                              </span>
                            );
                          })}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="py-6">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">
                  {selectedFilters.length > 0
                    ? `Exploring ${articles.length} item${
                        articles.length !== 1 ? 's' : ''
                      }`
                    : `Exploring all content`}
                </h1>
              </div>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <div className="py-4">
                  <CardGrid>
                    {articles.map((article) => {
                      return (
                        article.pageContext &&
                        article.pageContext.body && (
                          <Card
                            key={article.path}
                            metadata={
                              article.pageContext &&
                              article.pageContext.metadata
                                ? article.pageContext.metadata
                                : undefined
                            }
                            href={article.path}
                            readingTime={
                              article.readingTime && article.readingTime.minutes
                            }
                            createdAt={article.pageContext.createdAt}
                            references={article.pageContext.body.references}
                            heading={article.pageContext.title}
                            headingLevel={2}
                          >
                            {article.pageContext.introduction.plainText}
                          </Card>
                          // </li>
                        )
                      );
                    })}
                    {/* </ul> */}
                  </CardGrid>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ExplorePage;

export const exploreQuery = graphql`
  query {
    articles: allSitePage {
      nodes {
        pageContext
        path
        readingTime {
          minutes
        }
      }
    }
    tags: allContentfulTag {
      nodes {
        contentful_id
        name
      }
    }
  }
`;
